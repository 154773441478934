import axios from 'axios'
import { useQuery } from 'react-query'

const URL = `${process.env.NEXT_PUBLIC_API_ROOT}/activities/`

const useActivities = (params) => useQuery(['activities', params], async () => {
  const { data } = await axios.get(URL, { params })
  return data
}, {
  // enabled,
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 1000 * 60 * 24,
})

export default useActivities
