import React, { memo } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Image from 'next/image'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import clsx from 'clsx'
// import { useRouter } from 'next/router'
import usePageProps from 'hooks/usePageProps'
import clsx from 'clsx'
import sx from './styles'

const CategoryCard = ({
  item, onClickCategory, selected,
}) => {
  const { locale } = usePageProps()

  return (
    <Paper
      sx={sx.root}
      elevation={0}
      component="a"
      onClick={() => onClickCategory(item?.slug)}
      title={item?.name}
      className={clsx({
        selected: selected === item?.slug,
      })}
    >
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sx={sx.image}
        >
          <Image
            src={item?.image}
            alt={item?.title}
            layout="responsive"
            width={200}
            height={200}
            objectFit="cover"
            blurDataURL={item?.image}
            placeholder="blur"
            unoptimized
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ zIndex: 99 }}
        >
          <Box
            sx={sx.titleContainer}
            className={item?.slug}
          >
            <Box sx={sx.iconContainer}>
              <Image
                src={item?.icon}
                alt={item?.name}
                layout="fill"
                objectFit="contain"
                unoptimized
              />
            </Box>
            <Box>
              <Typography variant="body2">
                {locale === 'es' ? item?.name_es : item?.name_en}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default memo(CategoryCard)
