import React, { memo } from 'react'
import Grid from '@mui/material/Grid'
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography'
import CategoryCard from 'components/CategoryCard'
import usePageProps from 'hooks/usePageProps'
import { Container } from '@mui/material'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import sx from './styles'

const Categories = ({
  title,
  variantTitleElement = 'h2',
  sx: sxProp,
  withBorder = true,
  onClickCategory,
  variant = 'map', // state|map
}) => {
  const { categories } = usePageProps()
  const filtersMap = useSelector((s) => s.map)
  const filtersState = useSelector((s) => s.state)

  return (
    <Container maxWidth="lg">
      <Grid
        id="categorias"
        container
        display="flex"
        justifyContent="center"
      >
        <Grid
          item
          container
          xs={12}
          lg={10}
          sx={{ ...sx.root, ...sxProp }}
          className={clsx('Category-root', {
            withOutBorder: !withBorder,
          })}
        >
          {title && (
            <Grid
              item
              xs={12}
              mt={3}
            >
              <Typography
                variant={variantTitleElement}
                textAlign="center"
              >
                <FormattedMessage id={title} />
              </Typography>
            </Grid>
          )}

          <Grid
            mt={4}
            item
            xs={12}
            container
            spacing={2}
            sx={sx.swiper}
          >
            {categories?.map((category) => (
              <Grid
                item
                xs={6}
                md
                lg
                key={category?.name}
              >
                <CategoryCard
                  item={category}
                  onClickCategory={() => onClickCategory(category?.slug)}
                  key={`category-${category?.slug}`}
                  selected={variant === 'map'
                    ? filtersMap?.filters?.category?.includes(category?.slug)
                    : filtersState?.filters?.category?.includes(category?.slug)}
                />
              </Grid>
            ))}

          </Grid>

        </Grid>
      </Grid>
    </Container>
  )
}

export default memo(Categories)
