const styles = {
  root: {
    mt: 5,
    padding: { xs: 2, lg: 4 },
    borderRadius: 3,
    border: 1,
    borderColor: 'background.border',
    boxShadow: '0px 4px 40px 0px #0000000D',
    '&.withOutBorder': {
      border: 'none',
      boxShadow: 'none',
      marginTop: 0,
      padding: 0,
    },
  },
  swiper: {
    marginY: 2,
    width: { xs: '100%' },
    '& .swiper': {
      height: { xs: 320, lg: 'auto' },
    },
    '& .swiper-pagination': {
      order: 2,
      bottom: 10,
    },

    '& .swiper-pagination-bullet-active': {
      backgroundColor: (theme) => `${theme.palette.primary.main}!important`,
    },

    '& .swiper-button-next, .swiper-button-prev': {
      zIndex: 600,
      bottom: 0,
      top: '96%',
    },
    '& .swiper .swiper-slide': {
      width: { xs: '80%', lg: 'auto' },
    },
  },

  postSwiper: {
    cursor: 'pointer',
  },

}
export default styles
