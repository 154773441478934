const styles = {
  root: {
    position: 'relative',
    textDecoration: 'none',
    cursor: 'pointer',
    // filter: 'grayscale(1)',
    // '&.selected': {
    //   filter: 'grayscale(0)',
    // },
  },
  image: {
    display: { xs: 'none', md: 'block' },
    '& img': {
      borderRadius: '24px!important',
    },
  },

  titleContainer: {
    width: { md: 100 },
    height: { xs: 50, sm: 80, lg: 100 },
    margin: '0 auto',
    zIndex: 9999,
    display: 'flex',
    flexDirection: { xs: 'row', md: 'column' },
    justifyContent: { xs: 'space-evenly', md: 'center' },
    alignItems: 'center',
    borderRadius: 2,
    marginTop: { md: '-65px!important' },
  },

  iconContainer: {
    position: 'relative',
    width: { xs: 25, lg: 40 },
    height: { xs: 25, lg: 40 },
  },
}

export default styles
