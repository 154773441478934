import pickBy from 'lodash/pickBy'

export const filtersMap = {
  state: 'estado',
  category: 'categoria',
  states: 'estados',
}

export const InverseFiltersMap = Object.keys(filtersMap).reduce((acc, key) => ({
  ...acc,
  [filtersMap[key]]: key,
}), {})

export const getFilterObject = (filtersArray = {}) => {
  return filtersArray
    ? Object.keys(filtersArray).reduce((acc, curr) => {
      const key = InverseFiltersMap[curr]
      if (key) {
        const value = filtersArray[curr]
        acc[key] = value
      }
      return acc
    }, {})
    : {}
}

export const parseFiltersToUrl = (filtersObject) => {
  if (!filtersObject) return ''

  return Object.keys(pickBy(filtersObject))
    .reduce((acc, curr, index) => {
      if (filtersObject[curr]) {
        const operador = index === 0 ? '?' : '&'
        acc += `${operador}${filtersMap[curr]}=${filtersObject[curr]}`
      }
      return acc
    }, '')
}

export const cleanFilters = (filters = {}) => {
  if (!filters) return {}

  return Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = `${filters[key]}`.replace(/_/g, ',')
    }
    return acc
  }, {})
}
